<template>
	<aside class="widgets-popup widgets-popup--prompt">
		<div class="widgets-popup__header d-flex justify-end">
			<span class="close" @click="close"><v-icon>mdi-close</v-icon></span>
		</div>
		<h2 class="d-flex justify-center align-center">Add Response to Bank</h2>
		<section class="d-flex justify-center mt-16">
			<v-btn class="admin-primary-button mr-12">Add All Responses</v-btn>
			<v-btn class="admin-primary-button ml-12"
				>Add this response alone</v-btn
			>
		</section>
	</aside>
</template>
<script>
export default {
	name: "NewQuizPrompt",
	components: {},
	props: {},
	computed: {},
	data() {
		return {};
	},
	methods: {
		// close() {
		// 	this.$emit("closePopup", true);
		// }
	}
};
</script>

<style></style>
